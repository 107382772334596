<template lang="pug">
  .cbr-accounts-list
    v-data-table(v-show="false" :headers="headers")
    app-table(
      v-model="activeItems"
      :headers="headers"
      :list="list"
      :loading="loading"
      item-key="id"
      multi-sort=false
      :server-items-length="list.length"
      :show-select="true"
      :single-select="false"
      :mobile-breakpoint='NaN'
      :sort-by.sync="sortModel.by"
      :sort-desc.sync="sortModel.desc"
      @update:sort-desc="updateSorting"
      hide-default-header
      hide-default-footer
      class="app-table-new v-data-table theme--light"
    )
      template(#top)
        actions(
          :loading="loading"
          :disabled="!hasActiveItems"
          :activeItems="activeItems"
          @needToUpdate="$emit('needToUpdate')"
        )
      template(v-slot:item="{item, select, isSelected, index}")
        item(
          :item="item"
          :isSelected="isSelected"
          :select="select"
          :key="item.id"
          :index="index"
          @change:status="$emit('change:status', $event)"
          @needToUpdate="$emit('needToUpdate')"
        )
</template>

<script>
import SortModel from '../core/cbrAcc-sortModel'

export default {
  name: 'CbrAccountsList',

  props: {
    list: Array,
    headers: Array,
    loading: Boolean
  },

  data: () => ({
    activeItems: [],
    sortModel: null
  }),

  computed: {
    hasActiveItems() {
      return !!(this.activeItems && this.activeItems.length)
    },
  },

  created() {
    this.initSortModel()
  },

  methods: {
    initSortModel() {
      this.sortModel = new SortModel(this.$route.query)
    },

    async updateSorting() {
      let data = this.sortModel.getApiData(this.$route.query)
      let ordering
      if (data.ordering.length) ordering = data.ordering
      this.$emit('change:sorting', ordering)
    }
  },

  components: {
    appTable: () => import('@/components/global/GroupedTable.vue'),
    item: () => import('./CbrAccountsListItem.vue'),
    actions: () => import('./CbrAccountsActions.vue')
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/tableNew';

.app-table-new .v-data-table tbody tr td:first-child {
  width: 32px;
  height: 32px;
}

.cbr-accounts-list {
  min-height: 100vh;
}

.task-app-table {
  display: table;
  width: 100%;
}
</style>